import util from "lib/util"

export default {
  data() {
    return {
      expid: this.$route.query.expid || "",
      sign: this.$route.query.sign || "",
      stamid: this.$route.query.stampid || ""
    }

  },
  methods: {
    init() {
      this.canvasInit()
    },
    canvasInit() {
      var canvas = document.getElementById('canvas')
      var img = new Image()
      img.onload = () => {
        var canvas2d = canvas.getContext("2d")
        canvas2d.drawImage(img, 0, 0, 704, 1058)
        canvas2d.fillStyle = "#2e2e2e"
        canvas2d.font = "bold 30px Arial"
        canvas2d.fillText("寄", 28, 330)
        canvas2d.fillText("方", 28, 380)
        canvas2d.fillText("收", 28, 480)
        canvas2d.fillText("方", 28, 530)
        canvas2d.fillText("托", 28, 660)
        canvas2d.fillText("寄", 28, 700)
        canvas2d.fillText("物", 28, 740)
        canvas2d.fillText("物品名称：", 100, 620)
        canvas2d.fillText("重", 100, 660)
        canvas2d.fillText("量：", 190, 660)
        canvas2d.fillText("保价金额：", 100, 700)
        canvas2d.fillText("邮", 100, 740)
        canvas2d.fillText("费：", 190, 740)
        canvas2d.fillText("寄件时间：", 100, 780)
  
        canvas2d.fillStyle = "#989898"
        canvas2d.font = "normal 24px Arial"
        canvas2d.fillText("扫描二维码查快递", 254, 1022)
        //读取订单
        if(this.stampid){
          this.getExpidByStampid(result => {
            if(result.data && result.data.length > 0){
              this.expid = result.data[0].expId
              if(this.expid){
                this.getOrderReceipt()
              }
            }
          })
        } else{
          this.getOrderReceipt()
        }
      }
      img.src = '/images/canvas/bg_receipt.png'
    },
    getExpidByStampid(callback) {
      this.$http.get("/order/open/exclusiveVisit.do", {
        data: {
          type:"STAMPQUERY",
          stampid: this.stampid,
          sign: this.sign
        }
      }).then(result => {
        callback(result)
      })
    },
    getOrderReceipt() {
      this.getOrder((resultJson) => {
        var canvas = document.getElementById('canvas')
        var canvas2d = canvas.getContext("2d")
  
        //获取公司信息
        this.getsingleCom(resultJson.detail.kuaidiCom, (comJson) => {
          //黑色字
          canvas2d.fillStyle = "#545454"
          canvas2d.font = "bold 30px Arial"
          canvas2d.fillText((comJson.companyname || comJson.name || comJson.shortName), 150, 64)
  
          canvas2d.font = "normal 30px Arial"
          //寄件人信息
          var sendText = "";
          if(resultJson.detail.sendName){
            sendText += resultJson.detail.sendName + "  "
          }
          if(resultJson.detail.sendmobile || resultJson.detail.sendtel){
            sendText += (resultJson.detail.sendmobile || resultJson.detail.sendtel) + "  "
          }
          if(resultJson.detail.sendcompany){
            sendText += resultJson.detail.sendcompany + "  "
          }
          if(resultJson.detail.sendxzq && resultJson.detail.sendaddr){
            sendText += resultJson.detail.sendxzq.replace(/#/g, "") + resultJson.detail.sendaddr
          }
          this.drawLongText(sendText, canvas2d, 100, 312, 560, 42)
          //收件人信息
          var recText = ""
          if(resultJson.detail.recName){
            recText += resultJson.detail.recName + "  "
          }
          if(resultJson.detail.recmobile || resultJson.detail.rectel){
            recText += (resultJson.detail.recmobile || resultJson.detail.rectel) + "  "
          }
          if(resultJson.detail.reccompany){
            recText += resultJson.detail.reccompany + "  "
          }
          if(resultJson.detail.recxzq && resultJson.detail.recaddr){
            recText += resultJson.detail.recxzq.replace(/#/g, "") + resultJson.detail.recaddr
          }
          this.drawLongText(recText, canvas2d, 100, 462, 560, 42)
          //快递单号-居中
          if(resultJson.detail.kuaidiNum){
            var kuaidiNum = resultJson.detail.kuaidiNum.split("")
            kuaidiNum[0] = " " + kuaidiNum[0]
            for(var i in kuaidiNum){
              kuaidiNum[i] += " "
            }
            kuaidiNum = kuaidiNum.join("")
            canvas2d.font = "normal 30px Arial"
            canvas2d.textAlign = "center"
            canvas2d.fillText(kuaidiNum, canvas.width / 2, 245)
            canvas2d.textAlign = "left"
          }
  
          //灰色字
          canvas2d.fillStyle = "#989898"
          canvas2d.font = "bold 30px Arial"
          if(resultJson.detail.servicetype){
            canvas2d.fillText(resultJson.detail.servicetype, 150, 108)
          }
          canvas2d.font = "normal 30px Arial"
          if(resultJson.detail.cargo){
            canvas2d.fillText(resultJson.detail.cargo, 250, 620)
          } else {
            canvas2d.fillText("无", 250, 620)
          }
          if(resultJson.detail.weight){
            canvas2d.fillText(resultJson.detail.weight + "kg", 250, 660)
          }
          if(resultJson.detail.valins){
            canvas2d.fillText(resultJson.detail.valins + "元", 250, 700)
          }else{
            canvas2d.fillText("不保价", 250, 700)
          }
          if(+resultJson.detail.price){
            canvas2d.fillText(+resultJson.detail.payment == 'MONTHLY' ? '月结' : (resultJson.detail.price + "元"), 250, 740)
          } else {
            canvas2d.fillText(resultJson.detail.payment == 'CONSIGNEE' ? '到付' : '', 250, 740)
          }
          if(resultJson.detail.created){
            var createdTime = resultJson.detail.created.split(" ")[0]
            createdTime = createdTime.replace("-", "年").replace("-", "月") + "日"
            canvas2d.fillText(createdTime, 250, 780)
          }
  
          //条形码
          var imgBar = new Image()
          imgBar.onload = () => {
            canvas2d.drawImage(imgBar, 105, 140, 500, 70)
            //底部二维码
            var imgQr = new Image()
            imgQr.onload = function(){
              canvas2d.drawImage(imgQr, 266, 822, 170, 170)
  
              //快递公司logo
              var imgLogo = new Image();
              imgLogo.onload = function(){
                canvas2d.beginPath()
                canvas2d.arc(75, 75, 45, 0, Math.PI * 2) //75为圆心，45为半径
                canvas2d.closePath()
                canvas2d.clip()
                canvas2d.drawImage(imgLogo, 30, 30, 90, 90)
                canvas2d.restore()
  
                //转换成图片
                var canvasImg = this.convertCanvasToImage(canvas)
                canvasImg.style.width = document.documentElement.clientWidth >= 352 ? 352 + 'px' : document.documentElement.clientWidth * 0.95 + 'px'
                document.getElementById("canvasImg").appendChild(canvasImg)
                canvas.style.display = "none"
  
              }
              imgLogo.src = '/images/canvas/logo/' + resultJson.detail.kuaidiCom + '.png'
            }
            imgQr.src = '/twoCode.do?code=' + encodeURIComponent('https://m.kuaidi100.com/result.jsp?com=' + resultJson.detail.kuaidiCom + '&nu=' + resultJson.detail.kuaidiNum) + '&w=170&h=170'
          }
          imgBar.src = '/twoCode.do?code=' + resultJson.detail.kuaidiNum + '&w=500&h=70&&type=barcode'
        })
      });
    },
    getOrder(success){
      this.$http.get("/order/open/exclusiveVisit.do", {
        data: {
          type: "MKTSENTDETAIL",
          expid: this.expid,
          sign: this.sign
        }
      }).then(result => {
        success(result)
      })
    },
    getsingleCom (com, callback) {
      localStorage.removeItem("com_")
      var comJson = util.getStorage("com_" + com)
      if (!comJson) {
        this.$http.axios.get("/company.do?method=companyjs", {
          params: {
            number: com
          }
        }).then(res => {
          let data  = res.data
          util.setStorage("com" + com , data)
          callback(data)
        })
      } else {
        callback(comJson)
      }
    },
    //绘制长文本-自动换行
    drawLongText(longtext, ctx, beginWidth, beginHeight, maxWidth, fontHeight){
      var lineWidth = 0
      var lastSubStrIndex = 0; //每次开始截取的字符串的索引
      for(var i = 0; i < longtext.length; i ++){
        lineWidth += ctx.measureText(longtext[i]).width
        if(lineWidth > maxWidth){
          ctx.fillText(longtext.substring(lastSubStrIndex, i), beginWidth, beginHeight)//绘制截取部分
          beginHeight += fontHeight //20为字体的高度
          lineWidth = 0
          lastSubStrIndex = i
        }
        if(i == longtext.length - 1){//绘制剩余部分
          ctx.fillText(longtext.substring(lastSubStrIndex, i + 1), beginWidth, beginHeight)
        }
      }
    },
    convertCanvasToImage(canvas) {
      var image = new Image()
      image.src = canvas.toDataURL("image/png")
      return image
    }
  },
  mounted() {
    this.login(() => {
      this.init()
    }, () => {
      this.goLogin()
    })
  }
}